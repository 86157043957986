/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import axios from 'axios';


import * as Constants from "constants/constants.jsx";

import {
  WhatsappShareButton,
  TwitterShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappIcon,
  TwitterIcon,
  RedditIcon,
  TelegramIcon,

} from "react-share";

class ShowEmojiPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emojiUrl: "",
      emojiName: "",
      isLiked: false,
      emoji1: "1f611",
      emoji2: "1f611",
    };
    console.log(this.props.match.params[0]);
    if (this.props.match.params[0] === "") {
      this.requestNewEmoji();
    } else {
      this.state["emojiUrl"] = this.props.match.params[0] + ".svg"
      this.state["emojiName"] = "/" + this.state["emojiUrl"].split(".").slice(0, -1).join(".")
      let tmp = this.state["emojiName"].split("/")[2].split("_")
      this.state.emoji1 = tmp[0]
      this.state.emoji2 = tmp[1]
      console.log(this.state.emoji1)

    }
  }
  componentDidMount() {
    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }

  requestNewEmoji = (event) => {
    axios.get(Constants.BACKEND_URL + "/randomemojijson")
      .then((response) => {
        let emojiName = response.data.emojiv1.split(".").slice(0, -1).join(".")
        let tmp = emojiName.split("/")[2].split("_")
        this.setState({
          emojiUrl: response.data.emojiv1,
          emojiName: emojiName,
          isLiked: false,
          emoji1: tmp[0],
          emoji2: tmp[1]
        })
        this.props.history.push(this.state.emojiName);
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {

      });
  }

  likeEmoji = (event) => {
    if (this.state.isLiked === false) {
      axios.post(Constants.BACKEND_URL + "likeemoji/" + this.state.emojiName)
      this.setState({isLiked: !this.state.isLiked})
    }
  }


  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section className="section section-lg section-coins">
            <Container>
              <Row>
                <Col md={{ size: 8, offset: 2 }}>
                  <Card className="card-coin card-plain">
                    <CardBody>
                      <Row className="text-center">
                        <Col md={{ size: 8, offset: 2 }}>
                          <h3>
                            <img src={"https://twemoji.maxcdn.com/v/latest/svg/" + this.state.emoji1 + ".svg"} style={{width: "32px"}}></img>
                            +
                            <img src={"https://twemoji.maxcdn.com/v/latest/svg/" + this.state.emoji2 + ".svg"} style={{width: "32px"}}></img>
                            =
                          </h3>
                        </Col>
                      </Row>
                      <Row>
                        {this.state.emojiUrl !== "" ? (
                          <img
                            alt="..."
                            className="img-center img-fluid"
                            src={Constants.BACKEND_URL + "/randomemoji/" + this.state.emojiUrl}
                            onError={this.requestNewEmoji}
                          />
                        ) : null}
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button className="btn-simple" color="success" onClick={this.requestNewEmoji}>
                        Generate random emoji
                      </Button>
                      <Button className="btn-simple" onClick={this.likeEmoji}>
                        <i className="tim-icons icon-heart-2 " style={{color: (this.state.isLiked === true)?"red":""}}></i>
                      </Button>
                      <Row>
                        <Col md={{ size: 8, offset: 2 }}>
                          <WhatsappShareButton url={window.location.href} title={"Check out this emoji I made"} separator=" ">
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          {/* <FacebookMessengerShareButton url={window.location.href} title="Check out this funny emoji" appId="180126593048461">
                            <FacebookMessengerIcon size={32} round />
                          </FacebookMessengerShareButton> */}
                          <TwitterShareButton url={window.location.href} title="Check out this emoji I made">
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <TelegramShareButton url={window.location.href} title="Check out this emoji I made">
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                          <RedditShareButton url={window.location.href} title="Check out this emoji I made">
                            <RedditIcon size={32} round />
                          </RedditShareButton>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Container>
            
          </section>
          {/* <selection className="section section-lg section-coins">
          <Container>
              <Row>
                <Col md={{ size: 8, offset: 2 }}>
                  <Card className="card-coin card-plain">
                    <CardBody>
                      <Row>
                          <a href="https://www.vultr.com/?ref=8513253-6G">
                            <img src="https://www.vultr.com/media/banners/banner_728x90.png"></img>
                          </a>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
          </selection> */}
          <Footer />
        </div>
      </>
    );
  }
}

export default ShowEmojiPage;
